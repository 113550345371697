

































































































import { useExchangeRate } from '@/composition/exchangeRate'
import {
  exchangeRateService,
  PESOS_EXCHANGE_KEY,
} from '@/services/modules/exchangeRate'
import i18n from '@/setup/i18n'
import communique from '@/notification'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'ExchangeRateUpdate',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  setup() {
    const exchangeId = ref('')
    const amount = ref(0)

    const amountLabel = computed(() => i18n.t('exchangeRate') as string)
    const amountDescription = computed(
      () => i18n.t('exchangeRateDescription') as string
    )

    const { data, isLoading, error, retry } = useExchangeRate({
      exchangeKey: PESOS_EXCHANGE_KEY,
    })

    watch(
      () => data.value,
      (newValue) => {
        const [pesosExchangeRate] = newValue

        if (!pesosExchangeRate) return

        exchangeId.value = pesosExchangeRate.exchangeId
        amount.value = pesosExchangeRate.amount
      },
      {
        immediate: true,
      }
    )

    const isSubmitting = ref(false)

    async function submit() {
      try {
        if (!exchangeId.value) {
          throw new Error('Exchange id error')
        }

        isSubmitting.value = true

        await exchangeRateService.update({
          exchangeId: exchangeId.value,
          amount: amount.value,
        })

        communique.dispatch({
          variant: 'success',
          message: i18n.t('exchangeRateUpdateSuccess') as string,
        })
      } catch (error) {
        communique.dispatch({
          variant: 'error',
          message: i18n.t('exchangeRateUpdateError') as string,
        })
      } finally {
        isSubmitting.value = false
      }
    }

    return {
      amount,
      amountLabel,
      amountDescription,
      isLoading,
      error,
      submit,
      isSubmitting,
      retry,
    }
  },
})
