var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{ref:"form",class:[
      'grid gap-6 sm:rounded-md sm:overflow-hidden px-4 py-5 sm:p-6 bg-white',
      _vm.isLoading ? 'animate-pulse h-64' : 'shadow ' ],attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[(_vm.error)?_c('BaseBox',[_c('BaseBox',{staticClass:"grid gap-8"},[_c('BaseBox',{staticClass:"grid gap-4"},[_c('BaseAlert',{attrs:{"tone":"caution"}},[_c('BaseText',[_c('i18n',{attrs:{"tag":false,"path":"error.copy"}})],1)],1)],1),_c('BaseBox',{staticClass:"\n            flex flex-col\n            space-y-4\n            lg:flex-row lg:space-y-0 lg:space-x-4\n          "},[_c('BaseButton',{attrs:{"variant":"solid","tone":"tertiary"},on:{"click":_vm.retry}},[_c('i18n',{attrs:{"tag":false,"path":"error.cta.tryAgain"}})],1)],1)],1)],1):(!_vm.isLoading)?[_c('ValidationProvider',{attrs:{"name":_vm.amountLabel,"rules":{ required: true, min_value: 0 },"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('BaseFieldLabel',{attrs:{"label":_vm.amountLabel,"description":_vm.amountDescription,"description-id":"amount-description","html-for":"amount"}}),_c('BaseInputText',{attrs:{"id":"amount","type":"number","min":"0"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('BaseFieldMessage',{attrs:{"id":"amount-message","message":errors[0],"tone":"critical"}})]}}],null,true)}),_c('BaseBox',{staticClass:"hidden sm:block",attrs:{"aria-hidden":"true"}},[_c('BaseBox',{staticClass:"border-t border-gray-300"})],1),_c('BaseBox',{staticClass:"text-right"},[_c('BaseButton',{attrs:{"loading":_vm.isSubmitting,"tone":"tertiary","type":"submit"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"submit"}})]},proxy:true},{key:"loading-text",fn:function(){return [_c('i18n',{attrs:{"tag":false,"path":"submitLoading"}})]},proxy:true}],null,true)})],1)]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }